<template>
  <div class="rfiles" :class="{ wrong: form.errors[name] }" v-tooltip="form.errors[name] || ''">
    <label v-tooltip="help">{{ label }}</label>
    <div class="rfiles-container" style="height: 40px; width: 200px">
      <div class="rfiles-item" style="width: 32px" v-for="(item, iaf) in getValue()" :key="'file-' + name + '-' + iaf">
        <span class="rfiles-remove" v-if="
          !readonly &&
          !form.readonly &&
          !(
            form.mode == 'view' ||
            form.mode == 'empty' ||
            form.mode == 'search'
          )
        " @click="deleteItem(iaf)">
          <fa-icon icon="times-circle" size="1x" style="color: black; font-size: 12px" />
        </span>
        <a target="_blank" class="external" :href="item.file ? item.file : item.url">
          <img v-tooltip="item.nombre" :src="getSrc(item)" />
        </a>
      </div>
      <div class="rfiles-item" style="width: 32px" v-if="
        !readonly &&
        !form.readonly &&
        !(
          form.mode == 'view' ||
          form.mode == 'empty' ||
          form.mode == 'search'
        ) &&
        getValue().length < max
      ">
        <input multiple type="file" ref="input" style="display: none" @change="loadFile" />
        <a @click="$refs.input.click()">
          <img v-tooltip="$t('rfiles.anadir_documento')" :src="require('./../assets/attachment-icon-plus.png')" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import FieldMixin from "./FieldMixin.vue";
export default {
  inject: ["rformc"],
  mixins: [FieldMixin],
  props: {
    label: { type: String },
    max: { type: Number, default: 6 },
  },
  data: function () {
    return {
      form: this.rform ? this.rform : this.rformc,
    };
  },
  methods: {
    getValue: function () {
      return this.getContext()[this.getName()] || [];
      /*var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (typeof context == "undefined") {
          let n = namespaces[i];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            context = [];
          } else {
            context = {};
          }
        }
        context = context[namespaces[i]];
      }
      return context || [];*/
    },
    append: function (val) {
      let context = this.getContext(true);
      let name = this.getName();
      if (!context[name]) {
        this.$set(context, name, []);
      }
      context[name].push(val);
      this.onChange();
      /*var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (i == namespaces.length - 1) {
          if (
            typeof context[namespaces[i]] == "undefined" ||
            !context[namespaces[i]]
          )
            self.$set(context, namespaces[i], []);
          context[namespaces[i]].push(val);
        } else if (typeof context[namespaces[i]] == "undefined") {
          let n = namespaces[i + 1];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            self.$set(context, namespaces[i], []);
          } else {
            self.$set(context, namespaces[i], {});
          }
        }
        context = context[namespaces[i]];
      }*/
    },
    deleteItem: function (index) {
      let context = this.getContext(true);
      let name = this.getName();
      if (!context[name]) {
        this.$set(context, name, []);
      }
      this.$delete(context[name], index);
      if (!context[name]?.length) this.$set(context, name, false);
      this.onChange();
      /*var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (i == namespaces.length - 1) {
          if (
            typeof context[namespaces[i]] == "undefined" ||
            !context[namespaces[i]]
          )
            self.$set(context, namespaces[i], []);
          self.$delete(context[namespaces[i]], index);
          self.onChange();
        } else if (typeof context[namespaces[i]] == "undefined") {
          let n = namespaces[i + 1];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            self.$set(context, namespaces[i], []);
          } else {
            self.$set(context, namespaces[i], {});
          }
        }
        context = context[namespaces[i]];
      }*/
    },
    loadFile: function (event) {
      var self = this;
      Array.from(event.target.files).forEach((file) => {
        var reader = new FileReader();
        reader.onload = function (e) {
          self.append({
            nombre: file.name,
            extension: /(?:\.([^.]+))?$/.exec(file.name)[1], //file.type.split("/")[1],
            file: e.target.result,
          });
          self.form.loading = false;
        };
        reader.readAsDataURL(file);
        self.form.loading = true;
        self.$refs.input.value = null;
      });
    },
    getSrc(item) {
      var self = this;
      var ext = (item.nombre || "").split(".").pop();
      switch (ext.toLowerCase()) {
        case "pdf":
          return require("./../assets/pdf.png");
        case "doc":
        case "docx":
          return require("./../assets/doc.png");
        case "xls":
        case "xlsx":
          return require("./../assets/xls.png");
        case "png":
        case "jpg":
        case "jpeg":
        case "gif":
          return item.file ? item.file : item.url;
        default:
          return require("./../assets/attachment-icon.png");
      }
    },
    bindFields() {
      var self = this;
      //TODO: context
      self.form.fields.push({ name: self.name, fields: ["url", "nombre"] });
    },
  },
  mounted: function () {
    //window.console.log(this.form);
  },
};
</script>
<style>
.rfiles {
  flex-flow: column;
}

.rfiles-container {
  border: 1px solid #ccc;
  display: flex;
  padding: 3px;
  flex-wrap: wrap;
  align-items: center;
}

.wrong .rfiles-container {
  /*border: solid 1px #ff6a6a;*/
  background: rgba(255, 106, 106, 0.2);
}

.rfiles-item {
  cursor: pointer;
  position: relative;
  height: 100%;
  display: flex;
}

.rfiles-item a {
  display: flex;
  align-items: center;
}

.rfiles-item img,
.rfiles-item a {
  width: 100%;
  align-items: center;
}

.rfiles-remove {
  position: absolute;
  top: 0;
  right: 0px;
  background: white;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
</style>