var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.form.errors[_vm.name] || ''),expression:"form.errors[name] || ''"}],staticClass:"rfiles",class:{ wrong: _vm.form.errors[_vm.name] }},[_c('label',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.help),expression:"help"}]},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"rfiles-container",staticStyle:{"height":"40px","width":"200px"}},[_vm._l((_vm.getValue()),function(item,iaf){return _c('div',{key:'file-' + _vm.name + '-' + iaf,staticClass:"rfiles-item",staticStyle:{"width":"32px"}},[(
        !_vm.readonly &&
        !_vm.form.readonly &&
        !(
          _vm.form.mode == 'view' ||
          _vm.form.mode == 'empty' ||
          _vm.form.mode == 'search'
        )
      )?_c('span',{staticClass:"rfiles-remove",on:{"click":function($event){return _vm.deleteItem(iaf)}}},[_c('fa-icon',{staticStyle:{"color":"black","font-size":"12px"},attrs:{"icon":"times-circle","size":"1x"}})],1):_vm._e(),_c('a',{staticClass:"external",attrs:{"target":"_blank","href":item.file ? item.file : item.url}},[_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(item.nombre),expression:"item.nombre"}],attrs:{"src":_vm.getSrc(item)}})])])}),(
      !_vm.readonly &&
      !_vm.form.readonly &&
      !(
        _vm.form.mode == 'view' ||
        _vm.form.mode == 'empty' ||
        _vm.form.mode == 'search'
      ) &&
      _vm.getValue().length < _vm.max
    )?_c('div',{staticClass:"rfiles-item",staticStyle:{"width":"32px"}},[_c('input',{ref:"input",staticStyle:{"display":"none"},attrs:{"multiple":"","type":"file"},on:{"change":_vm.loadFile}}),_c('a',{on:{"click":function($event){return _vm.$refs.input.click()}}},[_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('rfiles.anadir_documento')),expression:"$t('rfiles.anadir_documento')"}],attrs:{"src":require('./../assets/attachment-icon-plus.png')}})])]):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }